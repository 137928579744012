.Action {
    display: flex;
    padding: 0px;
    box-sizing: content-box;
    touch-action: none;
    cursor: var(--cursor, pointer);
    border: none;
    appearance: none;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;

    @media (hover: hover) {
        &:hover {
            background-color: var(--hover-background, '#75757A');

            svg {
                fill: var(--hover-fill);
            }
        }
    }

    svg {
        height: 100%;
    }

    &:active {
        background-color: var(--background);

        svg {
            fill: var(--fill);
        }
    }

    &:focus-visible {
        outline: none;
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), 0 0px 0px 2px #4c9ffe;
    }
}
