@page {
    margin-top: 9mm;
    margin-bottom: 8mm;
    margin-left: 7mm;
    margin-right: 7mm;
}

@media print {
    body {
        overflow: hidden;
    }
    * {
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }
    .highcharts-container {
        width: 100% !important;
    }

    .highcharts-root {
        width: 100% !important;
    }
}

@media (max-width: 1000px) {
    @media print {
        body {
            width: 950px;
        }
        #container {
            max-width: 950px;
        }
    }
}
@media (min-width: 1001px) {
    @media print {
        body {
            width: 1360px;
        }
        #container {
            max-width: 1360px;
        }
    }
}
