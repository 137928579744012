@keyframes pop {
    0% {
        transform: scale(1);
        box-shadow: var(--box-shadow);
    }
    100% {
        transform: scale(var(--scale));
        box-shadow: var(--box-shadow-picked-up);
    }
}

.Wrapper {
    display: flex;

    &.sorting {
        transform: translateY(var(--translate-y, 0));
    }

    transform-origin: 0 0;
    touch-action: manipulation;

    &.dragOverlay {
        --scale: 1.05;
        --box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
            0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
        --box-shadow-picked-up: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
            -1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25);
        z-index: 9999;
    }
}

.Item {
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;
    outline: none;
    transform-origin: 50% 50%;
    -webkit-tap-highlight-color: transparent;
    white-space: nowrap;

    &:focus-visible {
        box-shadow: 0 0px 4px 1px #4c9ffe, 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
            0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
    }

    &:not(.withHandle) {
        touch-action: manipulation;
        cursor: grab;
    }

    &.dragging:not(.dragOverlay) {
        opacity: var(--dragging-opacity, 0.5);
        z-index: 9999;

        &:focus {
            box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
                0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
        }
    }

    &.dragging {
        z-index: 9999;
    }

    &.dragOverlay {
        cursor: inherit;
        animation: pop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
        transform: scale(var(--scale));
        box-shadow: var(--box-shadow-picked-up);
        opacity: 1;
        background-color: #fff;
    }

    &:hover {
        .Remove {
            visibility: visible;
        }
        .Handle {
            --row-hover-fill: #3078b5;
        }
    }
}

.Remove {
    visibility: hidden;
}

.Actions {
    display: flex;
}
